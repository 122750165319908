import * as React from "react";

const WebinarList = () => {

  return (
    <>
      <h4>Please Choose Your Webinars</h4>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-patent-power-play-turn-your-product-features-into-business-assets" aria-label="Patent Power Play: Turn Your Product Features into Business Assets">
          <strong>Patent Power Play: Turn Your Product Features into Business Assets</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-3-step-definitive-strategy-guide-to-protect-your-software-innovations" aria-label="3-Step Definitive Strategy Guide to Protect Your Software Innovations">
          <strong>3-Step Definitive Strategy Guide to Protect Your Software Innovations</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-mastering-trademark-protection-a-comprehensive-guide-2" aria-label="Mastering Trademark Protection: A Comprehensive Guide">
          <strong>Mastering Trademark Protection: A Comprehensive Guide</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-understanding-patents-from-idea-to-invention-2" aria-label="Understanding Patents: From Idea to Invention">
          <strong>Understanding Patents: From Idea to Invention</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-navigating-international-patent-laws-as-a-startup" aria-label="Navigating International Patent Laws As A Startup">
          <strong>Navigating International Patent Laws As A Startup</strong>
        </a>
      </p>
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-an-ai-powered-patent-drafting-solution-a-must-try-for-entrepreneurs" aria-label="An AI-Powered Patent Drafting Solution - A Must Try For Entrepreneurs">
          <strong>An AI-Powered Patent Drafting Solution - A Must Try For Entrepreneurs</strong>
        </a>
      </p>
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-big-brands-are-watching--protect-your-consumer-electronics-ip-before-they-steal-it" aria-label="Big Brands Are Watching: Protect Your Consumer Electronics IP Before They Steal It">
          <strong>Big Brands Are Watching: Protect Your Consumer Electronics IP Before They Steal It</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-avoid-the-ip-traps-that-destroy-hardware-startups" aria-label="Avoid the IP Traps That Destroy Hardware Startups: Patent Strategies You Need Today">
          <strong>Avoid the IP Traps That Destroy Hardware Startups: Patent Strategies You Need Today</strong>
        </a>
      </p>
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-why-big-automakers-could-erase-your-autonomous-vehicle-innovation" aria-label="Why Big Automakers Could Erase Your Autonomous Vehicle Innovation Without Proper Patents">
          <strong>Why Big Automakers Could Erase Your Autonomous Vehicle Innovation Without Proper Patents</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-how-to-patent-digital-health-innovations-before-they-are-copied" aria-label="FDA Approval Won't Save You: How to Patent Digital Health Innovations Before They're Copied">
          <strong>FDA Approval Won't Save You: How to Patent Digital Health Innovations Before They're Copied</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-ai-startups-are-losing-millions-to-patent-infringement-wars-how-to-defend-your-innovation-now" aria-label="AI Startups Are Losing Millions to Patent Infringement Wars: How to Defend Your Innovation Now">
          <strong>AI Startups Are Losing Millions to Patent Infringement Wars: How to Defend Your Innovation Now</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-blockchain-trump-is-in-blockchain-is-in" aria-label="2025's Secret Weapon: AI-Powered Patent Drafting for Innovators">
          <strong>Blockchain: Trump is in. Blockchain is in. It is going to be a boom!</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-an-ai-powered-patent-drafting-solution" aria-label="2025's Secret Weapon: AI-Powered Patent Drafting for Innovators">
          <strong>2025's Secret Weapon: AI-Powered Patent Drafting for Innovators</strong>
        </a>
      </p>

      <p>
        <a className="sec-clr" href="/webinar-3-steps-strategy-guide-to-protect-your-software-innovations" aria-label="Protect Your Code in 2025: A Solid 3-Step Strategy for Software Innovators">
          <strong>Protect Your Code in 2025: A Solid 3-Step Strategy for Software Innovators</strong>
        </a>
      </p>
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-ai-meets-ip-welcome-to-the-future-of-patent-drafting" aria-label="AI-IP Fusion in 2025: I Want To Show You An Insane Patent Drafting Tool!">
          <strong>AI-IP Fusion in 2025: I Want To Show You An Insane Patent Drafting Tool!</strong>
        </a>
      </p>

      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-turn-your-product-features-into-business-assets" aria-label="The Insane 2025 Process To Transform Features into IP Revenue Streams">
          <strong>The Insane 2025 Process To Transform Features into IP Revenue Streams</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-how-to-navigate-the-patenting-journey" aria-label="2025 Inventor's Guide: Navigating the Patent Process from Idea to Approval">
          <strong>2025 Inventor's Guide: Navigating the Patent Process from Idea to Approval</strong>
        </a>
      </p>
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-mastering-trademark-protection-a-comprehensive-guide" aria-label="Trademark Mastery 2025: All That You Must Know in 2025">
          <strong>Trademark Mastery 2025: All That You Must Know in 2025</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-understanding-patents-from-idea-to-invention" aria-label="From Idea to Patent: The Inventor's Journey Got Even More Dangerous in 2025">
          <strong>From Idea to Patent: The Inventor's Journey Got Even More Dangerous in 2025</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-intellectual-property-management-for-startups" aria-label="Startup IP Secrets They Don't Want You To Know in 2025">
          <strong>Startup IP Secrets They Don't Want You To Know in 2025</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-navigating-international-patent-laws" aria-label="Global Patent Playbook 2025: Navigating International IP (It's Extremely Dangerous!)">
          <strong>Global Patent Playbook 2025: Navigating International IP (It's Extremely Dangerous!)</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-ip-strategy-for-small-business-growth" aria-label="Small Biz IP Growth Hacks 2025: Grow Using Your Very Own Intellectual Property">
          <strong>Small Biz IP Growth Hacks 2025: Grow Using Your Very Own Intellectual Property</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-from-concept-to-commercialization-the-licensing-pathway" aria-label="2025 Patent Licensing Roadmap: From Concept to Cash">
          <strong>2025 Patent Licensing Roadmap: From Concept to Cash</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-demystifying-patent-claims-and-specifications" aria-label="Patent Claims Decoded 2025: Understanding the Heart of Your Patent">
          <strong>Patent Claims Decoded 2025: Understanding the Heart of Your Patent</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-trademark-infringement-prevention-and-resolution" aria-label="Put an Absolute Stop to Trademark Theft in 2025: Here's How!">
          <strong>Put an Absolute Stop to Trademark Theft in 2025: Here's How!</strong>
        </a>
      </p>
      
      <p className='sec-clr'>
        <a className="sec-clr" href="/webinar-leveraging-intellectual-property-for-e-commerce-success" aria-label="E-commerce Domination Using Intellectual Property: Leverage Your IP for Success in 2025">
          <strong>E-commerce Domination Using Intellectual Property: Leverage Your IP for Success in 2025</strong>
        </a>
      </p>
      

    </>
  )
}

export default WebinarList